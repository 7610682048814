/*
 * Skin: Black
 * -----------
 */
@import "../bootstrap-less/mixins.less";
@import "../bootstrap-less/variables.less";
@import "../variables.less";
@import "../mixins.less";

/* skin-black navbar */
.skin-black-light {
  //Navbar & Logo
  .main-header {
    .box-shadow(0px 1px 1px rgba(0, 0, 0, 0.05));
    .navbar-toggle {
      color: #333;
    }
    .navbar-brand {
      color: #333;
      border-right: 1px solid #eee;
    }
    .navbar {
      .navbar-variant(#fff; #333; #999; #fff);
      > .sidebar-toggle {
        color: #333;
        border-right: 1px solid #eee;
      }
      .navbar-nav {
        > li > a {
          border-right: 1px solid #eee;
        }
      }
      .navbar-custom-menu .navbar-nav,
      .navbar-right {
        > li {
          > a {
            border-left: 1px solid #eee;
            border-right-width: 0;
          }
        }
      }
    }
    > .logo {
      .logo-variant(#fff; #333);
      border-right: 1px solid #eee;
      @media (max-width: @screen-header-collapse) {
        .logo-variant(#222; #fff);
        border-right: none;
      }
    }

    li.user-header {
      background-color: #222;
    }
  }

  //Content Header
  .content-header {
    background: transparent;
    box-shadow: none;
  }
  //Create the sidebar skin
  .skin-light-sidebar(#fff);
}
